import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | GreenTurf
			</title>
			<meta name={"description"} content={"Больше чем поле - место для раскрытия вашего потенциала"} />
			<meta property={"og:title"} content={"Наши услуги | GreenTurf "} />
			<meta property={"og:description"} content={"Больше чем поле - место для раскрытия вашего потенциала"} />
			<meta property={"og:image"} content={"https://quivurs.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quivurs.com/img/9840655.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quivurs.com/img/9840655.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quivurs.com/img/9840655.png"} />
		</Helmet>
		<Components.Header />
		<Section background="#c0c0c0" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h1" margin="12px 0" font="--headline2" md-font="--headline3">
							Больше чем поле - место для раскрытия вашего потенциала
						</Text>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Поле GreenTurf Fields - это больше, чем просто участок травы, мы предоставляем услуги, которые улучшают ваш футбольный опыт и делают каждое посещение незабываемым.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://quivurs.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наши услуги
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">

						Идеальное поле
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Полноразмерное футбольное поле: Стандартное по размеру, пышное, ухоженное поле, готовое принять ваши матчи с качеством, которое вы найдете на профессиональных площадках.
						<br /><br />
						Вечернее освещение: Современное прожекторное освещение позволяет продолжать игру даже после захода солнца, создавая яркую атмосферу для игры в ночное время.

					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Комфорт и удобство
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Удобства на территории: Раздевалки для комфортного переодевания, закусочные, где можно подкрепиться, и просторная парковка, чтобы начать игру без стресса.
						<br /><br />
						Прокат оборудования: От мячей до конусов и вратарских перчаток - у нас есть все самое необходимое, чтобы вы могли играть, не беря с собой полную сумку инвентаря.

					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Бронировать легко
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Простое онлайн-бронирование: Наша удобная онлайн-система позволяет легко забронировать следующую игру.
						<br /><br />
						Гибкое расписание: Выбирайте из широкого спектра свободных мест, чтобы соответствовать расписанию вашей команды.

					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Ваша игра, ваш путь
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Индивидуальный опыт: Хотите организовать турнир или специальное мероприятие? Свяжитесь с нами, чтобы обсудить, как мы можем оборудовать поле в соответствии с вашими потребностями.
						<br/><br/>
Эксклюзивный доступ: Когда вы заказываете у нас, поле принадлежит вам и только вам - никаких прерываний, никакого обмена, только непрерывная игра.

					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Развивайтесь вместе с нами
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Развитие навыков: Хотите совершенствоваться? Следите за нашими предстоящими семинарами и тренировками, призванными поднять уровень вашей игры.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Повысьте уровень своей игры
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Примите острые ощущения от игры, и пусть поля GreenTurf станут фоном для вашего футбольного путешествия. Свяжитесь с нами, чтобы узнать больше, а еще лучше - приезжайте и посмотрите, что делает наше место местной любовью к игре.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});